import './App.css';
import { Navbar } from './Modules/Header/Navbar';
import Footer from './Modules/Footer/Footer';
import Home from "./Home";
import PageScrollProgressBar from "react-page-scroll-progress-bar";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Leistungen from './Leistungen';
import About from './AboutUs';
import { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Impressum from './Chips/impressum';
import Datenschutz from './Chips/Datenschutz';

function App() {
  const [themeMode, setThemeMode] = useState('dark');

  const handleThemeChange = (event, newTheme) => {
    if (newTheme !== null) {
      console.log(newTheme)

      if (newTheme == true) {
        setThemeMode("light");
      }
      else {
        setThemeMode("dark");
      }
    }
  };

  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: '#009900',
      },
      background: {
        default: themeMode === 'light' ? '#ffffff' : '#212121',
      },
      text: {
        primary: themeMode === 'light' ? '#000000' : '#ffffff',
      },
      transitions: {
        create: () => 'background-color 0.3s, color 0.3s',
      },
    }
  });

  const contentStyle = {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(),
  };


  return (

    <div className='wrapper' style={contentStyle}>
      <ThemeProvider theme={theme} >
        <PageScrollProgressBar color="#FAC300" bgColor="transparent" height="5.5rem" />

        <Router>
          <Navbar themeMode={themeMode} theme={theme} handleThemeChange={handleThemeChange} />
          <Routes>
            <Route path="/" element={<Home theme={theme} themeMode={themeMode} />} />
            <Route path="/leistungen" element={<Leistungen theme={theme} themeMode={themeMode} />} />
            <Route path="/about" element={<About theme={theme} themeMode={themeMode} />} />
            <Route path="/impressum" element={<Impressum theme={theme} themeMode={themeMode} />} />
            <Route path="/datenschutz" element={<Datenschutz theme={theme} themeMode={themeMode} />} />
        
          </Routes>
          <Footer themeMode={themeMode} theme={theme} />
        </Router>
      </ThemeProvider>
    </div>

  );
}

export default App;